import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { Link, graphql } from 'gatsby'

import { motion } from 'framer-motion'

import NavigationBar from '../components/NavigationBar'
import ImageContainer from '../components/ImageContainer'
import Button from '../components/Button'
import SectionHeader from '../components/SectionHeader'
import Paragraph from '../components/Paragraph'
import Quote from '../components/Quote'

import wosb from '../images/wosb.jpg'
import vmware from '../images/vmware.png'
import iso from '../images/ISO.jpg'
import LetsWorkTogether from '../components/LetsWorkTogether'
import Footer from '../components/Footer'
import { SEO } from '../components/SEO'
import FancyImage from '../components/FancyImage'

const IndexPage = ({ data }) => {
  const heroAnim = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0,
        ease: 'easeIn',
      },
    },
  }

  const d = data.homeYaml

  return (
    <div>
      <ImageContainer imgUrl={d.header_image}>
        <div className='container mx-auto'>
          <div className='md:mb-24'>
            <NavigationBar type='light' />
          </div>
          <motion.section
            className='flex flex-col gap-8 p-8 md:p-0'
            initial='hidden'
            animate='show'
            variants={heroAnim}
          >
            <div>
              <h1 className='text-5xl font-black text-white md:text-8xl'>
                <ReactMarkdown>{d.heading}</ReactMarkdown>
              </h1>
            </div>
            <div>
              <h2 className='text-xl font-light text-white md:text-2xl'>
                {d.subheading}
              </h2>
            </div>
            <div className='flex gap-6'>
              <Link to='/services'>
                <Button type='primary'>Our Services</Button>
              </Link>
              <Link to='/contact'>
                <Button>Contact Us</Button>
              </Link>
            </div>
          </motion.section>
        </div>
      </ImageContainer>
      <main>
        <div className='container p-4 mx-auto'>
          <SectionHeader>Who we are</SectionHeader>
          <div className='flex flex-col justify-between gap-4 md:gap-12 md:flex-row'>
            <div className='flex-1'>
              <Paragraph>
                <ReactMarkdown>{d.who_we_are}</ReactMarkdown>
              </Paragraph>
              <div className='p-8 md:p-0'>
                <Quote>{d.quote}</Quote>
              </div>
            </div>
            <div className='flex-1'>
              <FancyImage
                image={d.quote_image}
                classes='bg-gray-100'
                x={20}
                y={20}
              />
            </div>
          </div>
          <SectionHeader>Certifications</SectionHeader>
          <div className='grid grid-cols-2 gap-4 md:gap-24'>
            {d.certifications.map((cert, i) => (
              <div className='col-span-2 md:col-span-1'>
                <h3 className='mb-4 text-3xl font-light text-primary'>
                  {cert.name}
                </h3>
                <Paragraph>{cert.description}</Paragraph>
              </div>
            ))}
          </div>
          <div className='grid grid-cols-2 gap-6 my-8 md:grid-cols-3 lg:grid-cols-4 place-items-center'>
            <img
              className='h-12 md:h-24'
              src={wosb}
              alt='Woman Owned Small Business'
            />
            <img className='h-12 md:h-24' src={vmware} alt='VMWare Partner' />
            <img
              className='h-12 md:h-24'
              src={iso}
              alt='ISO 9001 Certification'
            />
          </div>
          <LetsWorkTogether />
        </div>
      </main>
      <Footer />
    </div>
  )
}

export default IndexPage

export const Head = () => <SEO />

export const query = graphql`
  query HomeQuery {
    homeYaml {
      certifications {
        description
        name
      }
      heading
      quote
      subheading
      who_we_are
      header_image
      quote_image
    }
  }
`
