import React from 'react'

const ImageContainer = ({ children, imgUrl }) => {
  return (
    <div 
      className='h-100 md:min-h-screen p-0 md:p-12'
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top left',
        backgroundRepeat: 'no-repeat'
      }}
    >
      { children }
    </div>
  )
}

export default ImageContainer