import React from 'react'
import { Link } from 'gatsby'

import { motion } from 'framer-motion'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

import Button from './Button'

import img from '../images/letsworktogether.jpg'

const LetsWorkTogether = () => {
  const backCardAnim = {
    hidden: {
      opacity: 0,
      rotate: 0,
    },
    show: {
      opacity: 1,
      rotate: -2,
      transition: {
        duration: 1,
        type: 'spring',
        bounce: 0.25,
        delay: 0.4,
      },
    },
  }

  const frontCardAnim = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0,
        staggerChildren: 0.2,
      },
    },
  }

  const cardItem = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
    },
  }

  return (
    <div className='relative my-16 h-72 md:h-96 md:my-24'>
      <motion.div
        className='relative z-50 flex flex-col items-center justify-center w-full h-full gap-6 rounded-xl'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${img})`,
          backgroundSize: 'cover',
        }}
        variants={frontCardAnim}
        initial='hidden'
        whileInView='show'
        viewport={{ once: true }}
      >
        <motion.div 
        variants={cardItem}
        viewport={{ once: true }}
        className='text-3xl font-bold text-white md:text-5xl'
        >
          Let's work together
        </motion.div>
        <motion.div variants={cardItem} viewport={{ once: true }}>
          <Link to='/contact'>
            <Button type='primary'>
              <FontAwesomeIcon icon={faPhone} className='mr-2' />
              Contact Us
            </Button>
          </Link>
        </motion.div>
      </motion.div>
      <motion.div
        className='box-content absolute top-0 left-0 z-0 w-full h-full bg-secondary rounded-xl'
        initial='hidden'
        whileInView='show'
        viewport={{ once: true }}
        variants={backCardAnim}
      ></motion.div>
    </div>
  )
}

export default LetsWorkTogether
