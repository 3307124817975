import React from 'react'
import { motion } from 'framer-motion'

const FancyImage = ({ image, classes, alt, x, y }) => {

  const backCardAnim = {
    hidden: {
      x: 0,
      y: 0,
    },
    show: {
      x: x,
      y: y,
      transition: {
        duration: 0.75,
        delay: 0.2,
        type: 'spring',
        bounce: 0.5,
      }
    }
  }

  return (
    <div className={`relative`}>
      <div className='relative z-50'>
        <img src={image} alt={alt} className={`rounded-lg aspect-auto w-full`}/>
      </div>
      <motion.div
        className={`hidden md:block rounded-lg z-0 absolute top-0 right-0 h-full w-full ${classes}`}
        initial='hidden'
        whileInView='show'
        variants={backCardAnim}
        viewport={{ once: true }}
      >
      </motion.div>
    </div>
  )
}

export default FancyImage
