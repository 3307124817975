import React from 'react'
import { motion } from 'framer-motion'

const Paragraph = ({ children }) => {

  const paragraphAnim = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
        delay: 0.1,
        ease: 'easeOut',
      },
    },
  }

  return (
    <motion.div 
      className='p-8 mb-10 text-lg bg-gray-100 rounded-lg paragraph-style'
      variants={paragraphAnim}
      initial='hidden'
      whileInView='show'
      viewport={{ once: true }}
    >
      { children }
    </motion.div>
  )
}

export default Paragraph