import React from 'react'
import { motion } from 'framer-motion'

const Quote = ({ children }) => {
  const quoteAnim = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        delay: 0.3,
      },
    },
  }

  return (
    <motion.div
      className='relative p-8 mx-auto bg-dark rounded-xl'
      initial='hidden'
      whileInView='show'
      variants={quoteAnim}
      viewport={{ once: true }}
    >
      <p className='relative z-50 text-2xl leading-relaxed text-white'>
        {children}
      </p>
      <div className='absolute z-50 font-serif text-secondary text-9xl -top-6 -left-8'>
        “
      </div>
      <div className='absolute z-50 font-serif leading-3 -right-8 -bottom-10 text-secondary text-9xl'>
        ”
      </div>
    </motion.div>
  )
}

export default Quote
